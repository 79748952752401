<template>
  <div class="flex-container non-scrollable">
    <DataTable ref="dt" :value="independents" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber"
               selection-mode="single" dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu"
               :loading="loading" sort-mode="multiple" :multi-sort-meta="multipleSort"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.portes')})"
               scrollable scroll-height="flex" @rowSelect="clickPorte">
      <template #header>
        <div class="p-d-flex flex justify-content-between">
          <div class="flex flex-row gap-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
            </span>
            <div v-if="filterValue === SELECT_BUTTON.ALL" class="flex align-items-center ml-2">
              <Checkbox class="mr-2" v-model="showOutIndependent" :binary="true"/>
              <span>Afficher les portés sortis</span>
            </div>
          </div>

          <div class="flex flex-row gap-2">
            <SelectButton v-if="filtersSet" v-model="filterValue" :options="filtersOptions" option-value="value" option-label="label"
                          @update:modelValue="filterBy"/>
          </div>
          <Button :disabled="portes.length === 0" :class="{ 'disabled-button': portes.length === 0 }" class="h-auto"
                  :loading="exportSheetLoading" icon="pi pi-external-link" :label="$t('export')" @click="createSheet" />
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column field="last_name" :header="$t('lastname').toUpperCase()" sortable filterField="last_name" sortField="last_name"
              :showFilterMatchModes="false" class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="lastnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="first_name" :header="$t('firstname').toUpperCase()" sortable filterField="first_name" sortField="first_name"
              :showFilterMatchModes="false" class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="firstnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="missions" :header="$t('missionsContracts').toUpperCase()" class="set-column">
        <template #body="{data}">
          <div class="flex align-items-center">
            <span>{{ `${getCompleteMissions(data.missions)}/${data.missions.length}` }}</span>
            <Button class="ml-3" type="button" icon="pi pi-search" label="Détails" @click="(e) => openOverlay(e, data.id)" aria-haspopup="true" aria-controls="overlay_panel" />
          </div>
          <OverlayPanel :ref="data.id" appendTo="body" :showCloseIcon="true" id="overlay_panel" style="width: 450px" :breakpoints="{'960px': '75vw'}">
            <DataTable :value="data.missions" responsiveLayout="scroll" :sort-field="sortByEndingDate" :sort-order="1">
              <Column field="jsonClientFinal.fullName" header="Nom" sortable style="width: 50%"></Column>
              <Column :header="$t('missionsContracts').toUpperCase()" style="width: 50%">
                <template #body="{data}">
                  <span>{{ `${data.status === 'complete' ? '1' : '0'}/1` }}</span>
                </template>
              </Column>
              <Column :header="$t('endDate').toUpperCase()" style="width: 50%" sortable :sort-field="sortByEndingDate">
                <template #body="{data}">
                  <span :style="{color: getEndContractColor(data)}">
                    {{ getDateFormatted(getContractEndingDate(data), {day: '2-digit', month: '2-digit', year: 'numeric'}) }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </OverlayPanel>
        </template>
      </Column>
      <Column field="missions" :header="$t('workContract').toUpperCase()" class="set-column">
        <template #body="{data}">
          <Chip
            :class="(data?.last_document_contract?.status === constants.DOCUMENT_STATUS.VALIDATED || data?.last_document_contract?.status === constants.DOCUMENT_STATUS.SIGNED) ? 'custom-chip-validated' : 'custom-chip-waiting'"
            :label="getOptionLabel(constants.data.documentOptions, data?.last_document_contract?.status)?.toUpperCase()"
          />
        </template>
      </Column>
      <Column field="phone" :header="$t('phone').toUpperCase()" sortable filterField="phone" sortField="phone"
              :showFilterMatchModes="false" class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          {{data.phone?.replaceAll('-', '.')}}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Téléphone</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="phonesFilter" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="status" :header="$t('status').toUpperCase()" sortable filterField="status" sortField="status" :showFilterMatchModes="false"
              class="set-column" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <Chip :class="'custom-chip-' + data.status" :label="getOptionLabel(constants.data.independentOptions, data.status)?.toUpperCase()"></Chip>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Statut</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="constants.data.independentOptions" option-value="value" optionLabel="label" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span :class="'badge status-' + slotProps.option.value">{{slotProps.option.label}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SheetGenerator from "@/utils/SheetGenerator";
import {mapState} from "vuex";
import {
  callbackObjectColors,
  callbackObjectSort,
  getContractEndingDate,
  getOptionLabel,
  getEndContractAction
} from "@/utils/Misc";
import {getDateFormatted} from "@/utils/Date";
import {FilterMatchModeCustom} from "@/utils/FiltersRegistration";
export default {
  name: "RHTableauPorte",
  props: ['portes'],
  data() {
    return {
      customers: null,
      selectedCustomers: null,

      filtersSet: false,
      filtersOptions: [],
      filterValue: null,

      multipleSort: [],

      filters: {
        'global': {value: null, matchMode: null},
        'last_name': {value: null, matchMode: FilterMatchMode.IN},
        'first_name': {value: null, matchMode: FilterMatchMode.IN},
        'email': {value: null, matchMode: FilterMatchMode.IN},
        'phone': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
      },

      SELECT_BUTTON: {
        TODO: 'todo',
        PENDING: 'pending',
        ALL: 'all',
      },

      showOutIndependent: false,

      loading: true,
      exportSheetLoading: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    independents () {
      return this.portes.filter((independent) => this.showOutIndependent ? independent.status === this.constants.INDEPENDENT_STATUS.OUT :
          independent.status !== this.constants.INDEPENDENT_STATUS.OUT)
    },
    lastnamesFilter () {
      let array = this.portes.map((p) => p.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.portes.map((p) => p.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    emailsFilter () {
      let array = this.portes.map((p) => p.email)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    phonesFilter () {
      let array = this.portes.map((p) => p.phone)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item,
            label: item?.replaceAll('-', '.')
          })
        }
      })
      return filterArray
    },
  },
  mounted() {
    this.initFilters()
    this.initSorts()
    process.nextTick(() => {
      this.loading = false;
    })
  },
  methods: {
    getDateFormatted,
    getContractEndingDate,
    getOptionLabel,
    callbackObjectSort,
    callbackObjectColors,
    getEndContractAction,
    initFilters () {
      this.filtersSet = false
      this.filtersOptions = [
        {
          label: 'À traiter',
          value: this.SELECT_BUTTON.TODO,
          clicked: false
        },
        {
          label: 'En attente de signature',
          value: this.SELECT_BUTTON.PENDING,
          clicked: false
        },
        {
          label: 'Tous',
          value: this.SELECT_BUTTON.ALL,
          clicked: false
        }
      ]
      this.filterValue = this.getInitialFilterStatus()
      this.filters = {
        'global': {value: null, matchMode: null},
        'last_name': {value: null, matchMode: FilterMatchMode.IN},
        'first_name': {value: null, matchMode: FilterMatchMode.IN},
        'last_document_contract': {value: null, matchMode: FilterMatchMode.IN},
        'last_document_contract.status': {value: null, matchMode: FilterMatchMode.IN},
        'phone': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
      }
      this.filterBy(this.filterValue)
      this.filtersSet = true
    },
    sortByEndingDate (mission) {
      return this.getEndContractAction({ value: getContractEndingDate(mission), renewalValue: mission.jsonMission.renewal }, this.constants, this.callbackObjectSort())
    },
    getEndContractColor (mission) {
      return this.getEndContractAction({ value: getContractEndingDate(mission), renewalValue: mission.jsonMission.renewal }, this.constants, this.callbackObjectColors())
    },
    initSorts () {
      this.multipleSort = [
        { field: 'status', order: 1 },
        { field: 'last_name', order: 1 }
      ]
    },
    getInitialFilterStatus () {
      if (this.portes.findIndex(porte => porte.status === this.constants.INDEPENDENT_STATUS.DRAFT && !porte.last_document_contract) !== -1) {
        return this.SELECT_BUTTON.TODO
      } else {
        return this.SELECT_BUTTON.ALL
      }
      // else if (this.portes.findIndex(porte => porte?.last_document_contract?.status === this.constants.DOCUMENT_STATUS.PENDING_SIGNATURE) !== -1) {
      //   return { key: 'last_document_contract.status', filterValue: this.SELECT_BUTTON.PENDING, values: [this.constants.DOCUMENT_STATUS.PENDING_SIGNATURE], value: this.constants.DOCUMENT_STATUS.PENDING_SIGNATURE }
      // }
    },
    filterBy (filterValue) {
      switch (filterValue) {
        case this.SELECT_BUTTON.TODO:
          this.showOutIndependent = false
          this.filters = {
            ...this.filters,
            'status': {value: [null, this.constants.INDEPENDENT_STATUS.DRAFT], matchMode: FilterMatchMode.IN},
            'last_document_contract': {value: null, matchMode: FilterMatchModeCustom.EQUALS_EXACT},
            'last_document_contract.status': {value: null, matchMode: FilterMatchMode.IN},
          }
          break
        case this.SELECT_BUTTON.PENDING:
          this.showOutIndependent = false
          this.filters = {
            ...this.filters,
            'status': {value: null, matchMode: FilterMatchMode.IN},
            'last_document_contract': {value: null, matchMode: FilterMatchMode.IN},
            'last_document_contract.status': {value:[ this.constants.DOCUMENT_STATUS.PENDING_SIGNATURE], matchMode: FilterMatchMode.IN},
          }
          break
        default:
          this.filters = {
            ...this.filters,
            'status': {value: null, matchMode: FilterMatchMode.IN},
            'last_document_contract': {value: null, matchMode: FilterMatchMode.IN},
            'last_document_contract.status': {value: null, matchMode: FilterMatchMode.IN},
          }
          this.filterValue = this.SELECT_BUTTON.ALL
          break
      }
    },
    openOverlay (event, id) {
      this.$refs[id].toggle(event)
    },
    clickPorte (porte) {
      this.$router.push({ name: 'RHProfil', query: { idIndependent: porte.data.id }})
    },
    getCompleteMissions (missions) {
      let counter = 0
      missions.forEach((mission) => {
        if (mission.status === 'complete') {
          ++counter
        }
      })
      return counter
    },
    async createSheet () {
      this.exportSheetLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(porte => porte.id)
      SheetGenerator({ ids, exportAll: false }, '/rh/export-sheet', this)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    }
  }
}
</script>

