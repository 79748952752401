<template>
  <div class="flex-container non-scrollable">
    <page-title value="Ressources humaines"/>
    <r-h-tableau-porte :portes="independents"/>
  </div>
</template>

<script>

import RHTableauPorte from "@/components/RH/RHTableauPorte";
import {mapState} from "vuex";
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "RHPortes",
  components: {PageTitle, RHTableauPorte},
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      independents: state => state.independent.independents
    })
  },
  async created () {
    this.loaded = false
    await this.initIndependents()
    this.loaded = true
  },
  methods: {
    async initIndependents () {
      await this.$store.dispatch('independent/getAllIndependents', { onlyRhValidated: true })
    }
  }
}
</script>

<style scoped>

</style>
